<template>
    <CollapseCardGroup v-editable="blok" :data="collapseCardGroupData" />
</template>

<script setup lang="ts">
import CollapseCardGroupType from '~/types/CollapseCardGroupType';
import CollapseCardType from '~/types/CollapseCardType';
import ButtonType from '~/types/ButtonType';
import CollapseCardGroup from '~/components/page-building/collapse-card-group/CollapseCardGroup.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const collapseCardGroupData = computed<CollapseCardGroupType>(() => {
    const buttons = props.blok.buttons
        ? props.blok.buttons.map((button: any) => {
              return {
                  label: button.label,
                  style: button.style,
                  url: button.link.cached_url,
                  vEditable: button,
              } as ButtonType;
          })
        : [];

    const cards = props.blok.cards
        ? props.blok.cards.map((card: any) => {
              return {
                  title: card.title,
                  content: richTextToHtml(card.content) as string,
                  vEditable: card,
              } as CollapseCardType;
          })
        : [];

    return {
        heading: props.blok.heading,
        buttons,
        cards,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
    };
});
</script>
